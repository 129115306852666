(() => {
  // init namespaces
  window.AWE ??= {};
  AWE.component ??= {};

  const NATIVE_COOKIE_SYMBOL = Symbol("Cookie");
  const ONE_YEAR_IN_MS = 1000 * 60 * 60 * 24 * 365;
  const ACTIVE_COOKIE_CONSENT_BODY_CLASSNAME = "active-cookie-consent";
  const ALWAYS_ENABLED_COOKIE_CATEGORY = "essential";

  const {
    categoryTitles,
    cookieCategories,
    cookieDomain,
    cookieName: consentCookieName,
    configUrl,
    isCookiePolicyPage,
    reportUrl,
    whitelistRegExp,
  } = window.config.cookieConsent;

  // the max cookie size in bytes for a name=value in this case for the consent cookie, 1 is removed for "="
  const maxCookieSize = 4096 - consentCookieName.length - 1;

  // since there is no module import functionality available here yet: https://stackoverflow.com/a/2117523
  const uuidv4 = () =>
    ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );

  const getCookieValue = (cookieName) => {
    return document.cookie
      .split(";")
      .map((cookie) => {
        const [name, value] = cookie.split("=");

        return { name: name.trim(), value };
      })
      .find(({ name }) => name === cookieName)?.value;
  };

  const getCookieNames = (cookies = document.cookie) => {
    return cookies.split(";").map((cookie) => {
      const [name] = cookie.split("=");

      return name.trim();
    });
  };

  const getExpirationText = (seconds) => {
    if (seconds === null) {
      return "Session";
    }

    const minutes = Math.round(seconds / 60);
    const hours = Math.round(minutes / 60);
    const days = Math.round(hours / 24);
    const years = Math.round(days / 365);

    if (years > 0) {
      return years > 1 ? `${years} years` : `${years} year`;
    }
    if (days > 0) {
      return days > 1 ? `${days} days` : `${days} day`;
    }
    if (hours > 0) {
      return hours > 1 ? `${hours} hours` : `${hours} hour`;
    }
    if (minutes > 0) {
      return minutes > 1 ? `${minutes} minutes` : `${minutes} minute`;
    }

    if (seconds > 0) {
      return seconds > 1 ? `${seconds} seconds` : `${seconds} second`;
    }

    return "";
  };

  const postRequest = (data = {}) =>
    fetch(reportUrl, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

  const reportConsents = (
    { cookieApprovals, consentBanner, id, url },
    consentConfigurationId,
    approvedCategories
  ) =>
    postRequest({
      id,
      ip: "127.0.0.1",
      url,
      consentBanner,
      cookieApprovals,
      approvedCategories,
      consentConfigurationId,
    });

  /**
   * Functionality:
   *
   * - cookies are waiting to be filtered until config is loaded (if it fails to load they are not filtered)
   * - if there is a consent cookie set and config is different it will be saved/set again based on the new config
   * - overlay will be opened if there is no consent cookie set (or user manually opens them via cookie-policy page)
   * - cookie tied scripts will be running based on cookie after load or based on user selection from overlay
   */
  class CookieConsentService {
    constructor() {
      this.overrideCookiePrototype();
      this.fetchConsentConfig().then((config) => {
        if (config) {
          this.checkConfigAndConsentDiff();
          this.runScriptTags(this.getConsentedCategories());

          // fetch could finish before/after document is loaded
          if (document.readyState === "complete") {
            this.setupOverlays();
          } else {
            window.addEventListener("load", () => this.setupOverlays());
          }
        }
      });
    }

    fetchConsentConfig() {
      if (this.consentFetch) {
        return this.consentFetch;
      }

      this.consentFetch = window
        .fetch(configUrl)
        .then((r) => r.json())
        .then((json) => {
          this.config = json;

          return this.config;
        })
        .catch(() => {
          // if cookie consent fails to load we will get notified, cookies will be let-through without a config
          window.Sentry?.captureException(
            "Cookie consent config could not be loaded"
          );
        });

      return this.consentFetch;
    }

    /**
     * Creates a proxy-like override on the native cookie get/set to enable filtering of cookies being set
     */
    overrideCookiePrototype() {
      const service = this;

      Object.defineProperty(
        Document.prototype,
        NATIVE_COOKIE_SYMBOL,
        Object.getOwnPropertyDescriptor(Document.prototype, "cookie")
      );

      Object.defineProperty(Document.prototype, "cookie", {
        enumerable: true,
        configurable: true,

        get() {
          return this[NATIVE_COOKIE_SYMBOL];
        },

        set(cookie) {
          const cookieName = getCookieNames(cookie)[0];

          service.isCookieAllowed(cookieName).then((isAllowed) => {
            if (isAllowed) {
              // Note: setting the cookie does not override the entire cookie string, it concatenates the value
              this[NATIVE_COOKIE_SYMBOL] = cookie;
            }
          });
        },
      });
    }

    async showOverlayIfNeeded() {
      await this.fetchConsentConfig();

      if (this.config && !this.getConsentValue() && !isCookiePolicyPage) {
        this.openConsentOverlay();
      }
    }

    setupOverlays() {
      this.setupSimpleConsentOverlay();
      this.setupManageConsentOverlay();
    }

    setupSimpleConsentOverlay() {
      const acceptAllButtons = document.querySelectorAll(
        ".cookie-consent button[data-type='accept-all']"
      );
      const rejectAllButtons = document.querySelectorAll(
        ".cookie-consent button[data-type='reject-all']"
      );
      const manageButton = document.querySelector(
        ".cookie-consent-manage-link"
      );

      const acceptAllCookies = () => {
        this.saveConsent(cookieCategories);
        this.closeConsentOverlay();
      };

      const rejectAllCookies = () => {
        this.saveConsent([ALWAYS_ENABLED_COOKIE_CATEGORY]);
        this.closeConsentOverlay();
      };

      const onManageButtonClick = (event) => {
        event.preventDefault();
        this.openManageConsentOverlay();
      };

      // these handlers will be garbage collected on element removal
      acceptAllButtons.forEach((button) =>
        button.addEventListener("click", acceptAllCookies)
      );
      rejectAllButtons.forEach((button) =>
        button.addEventListener("click", rejectAllCookies)
      );

      if (manageButton) {
        manageButton.addEventListener("click", onManageButtonClick);
      }

      this.showOverlayIfNeeded();
    }

    setManageConsentCheckboxes() {
      const { defaultCheckedCookieCategories } = this.config;
      const dataCookieCheckboxAttribute = "data-cookie";

      const checkboxElements = document.querySelectorAll(
        `[${dataCookieCheckboxAttribute}]`
      );

      const activeCategories = Array.from(
        new Set([
          ...defaultCheckedCookieCategories,
          ...this.getConsentedCategories(),
        ])
      );

      checkboxElements.forEach((element) => {
        const cookieCategory = element.getAttribute(
          dataCookieCheckboxAttribute
        );

        element.checked = activeCategories.includes(cookieCategory);
      });
    }

    setupManageConsentOverlay() {
      const form = document.querySelector(".privacy-center-form");

      const handleFormSubmit = (ev) => {
        ev.preventDefault();

        const nodeList = [].slice.call(
          ev.target.querySelectorAll("[data-cookie]")
        );

        const consentedCategories = nodeList
          .map(function (input) {
            return input.checked && input.name;
          })
          .filter(function (input) {
            return !!input;
          })
          // add essential cookies (should be always set for user)
          .concat(ALWAYS_ENABLED_COOKIE_CATEGORY);

        this.saveConsent(consentedCategories);
        this.closeConsentOverlay();
      };

      form.addEventListener("submit", handleFormSubmit);
    }

    getConsentOverlay() {
      return document.querySelector(".cookie-consent");
    }

    openConsentOverlay() {
      const overlay = this.getConsentOverlay();

      if (overlay) {
        this.setOpenOverlayState(overlay);
      }
    }

    openManageConsentOverlay() {
      const overlay = this.getConsentOverlay();

      if (overlay) {
        this.setManageConsentCheckboxes();

        overlay.setAttribute("data-step", "privacy-center");
        this.setOpenOverlayState(overlay);
      }
    }

    setOpenOverlayState(overlay) {
      overlay.removeAttribute("disabled");
      document.body.classList.add(ACTIVE_COOKIE_CONSENT_BODY_CLASSNAME);
    }

    closeConsentOverlay() {
      const overlay = this.getConsentOverlay();

      if (overlay) {
        overlay.setAttribute("disabled", "true");
      }

      document.body.classList.remove(ACTIVE_COOKIE_CONSENT_BODY_CLASSNAME);
    }

    async isCookieAllowed(cookieName) {
      await this.fetchConsentConfig();

      // config is missing when there is an error either on json file part or processing the config
      if (!this.config || whitelistRegExp.test(cookieName)) {
        return true;
      }

      const consentValueInCookie = this.getConsentValue();
      const debugCookies = this.getDebugCookies();

      if (consentValueInCookie) {
        const isCookieAllowed =
          consentValueInCookie.cookieApprovals?.[cookieName] ||
          debugCookies.includes(cookieName);

        if (cookieName !== consentCookieName && !isCookieAllowed) {
          return false;
        }
      }

      return true;
    }

    /**
     * Returns the cookie category e.g. "analytical"
     *
     * @param {string} name
     * @return {string|undefined}
     */
    getCookieCategory(name) {
      return this.config.cookies.filter((cookie) => cookie.name === name)?.[0]
        ?.category;
    }

    getConsentValue() {
      const consentValueInCookie = getCookieValue("auth_consent_given");

      if (!consentValueInCookie) {
        return null;
      }
      try {
        return JSON.parse(decodeURIComponent(consentValueInCookie));
      } catch (error) {
        console.error(error);
        return null;
      }
    }

    /**
     * Returns and stores (for later reuse) the feature flag and debug related cookies, e.g. ["minionStatus"]
     * @return {[]}
     */
    getDebugCookies() {
      if (this.config && !this._debugCookies) {
        this._debugCookies = [];

        this.config.cookies.forEach((cookieConfig) => {
          if (!cookieConfig.isVisible) {
            this._debugCookies.push(cookieConfig.name);
          }
        });
      }

      return this._debugCookies;
    }

    getKnownCookiesWithCategories() {
      return this.config.cookies.reduce((acc, cookie) => {
        // eslint-disable-next-line no-param-reassign
        if (cookie.category.length > 0) {
          acc[cookie.name] = cookie;
        }
        return acc;
      }, {});
    }

    /**
     * Returns the consented categories based on what the user already consented to
     * e.g.
     *   if they consented to awe_session => ["essential"]
     *   if they consented to both awe_session and _ga => ["analytical", "essential"]
     *
     * @return string[]
     */
    getConsentedCategories() {
      const consentValueInCookie = this.getConsentValue();
      let consentedCategories = [];

      if (consentValueInCookie) {
        const { cookieApprovals } = consentValueInCookie;

        Object.keys(cookieApprovals).forEach((cookie) => {
          const category = this.getCookieCategory(cookie);

          if (
            category &&
            !consentedCategories.includes(category) &&
            cookieApprovals[cookie]
          ) {
            consentedCategories.push(category);
          }
        });
      }

      return consentedCategories;
    }

    /**
     * Saves the consent and the related cookie if there is a difference in the cookie and the (json) config
     */
    checkConfigAndConsentDiff() {
      const consentValue = this.getConsentValue();
      const debugCookies = this.getDebugCookies();

      if (consentValue) {
        const cookieNamesFromConfig = Object.keys(
          this.getKnownCookiesWithCategories()
        );
        const cookieNamesFromValue = Object.keys(consentValue.cookieApprovals);

        const difference = cookieNamesFromConfig
          // if cookie name from config is not in consent and is not a debug cookie
          .filter(
            (x) =>
              !cookieNamesFromValue.includes(x) && !debugCookies.includes(x)
          )
          // if cookie name in consent value is not in config
          .concat(
            cookieNamesFromValue.filter(
              (x) => !cookieNamesFromConfig.includes(x)
            )
          );

        // new cookie(s) found
        if (difference.length > 0) {
          this.saveConsent(this.getConsentedCategories());
        }
      }
    }

    generateCookieConsentValue() {
      const consentValueId = uuidv4();
      const { consentBanner, cookies, defaultCheckedCookieCategories } =
        this.config;
      const createdAt = new Date().toISOString();
      const cookieApprovals = {};

      cookies.forEach(({ category, name, isVisible }) => {
        if (category && isVisible) {
          cookieApprovals[name] =
            defaultCheckedCookieCategories.includes(category);
        }
      });

      return {
        id: consentValueId,
        createdAt,
        consentBanner,
        cookieApprovals,
        url: window.location.origin,
      };
    }

    /**
     * Saves consent via report endpoint and to consent cookie (if it fits cookie size restriction)
     *
     * @param {string[]} consentedCategories   e.g. ["analytical", "essential"]
     * @return {Promise<void>}
     */
    async saveConsent(consentedCategories) {
      const cookieValue = this.generateCookieConsentValue();
      const approvedCategories = [];

      Object.keys(cookieValue.cookieApprovals).forEach((cookie) => {
        const category = this.getCookieCategory(cookie);
        const isApproved = consentedCategories.includes(category);

        cookieValue.cookieApprovals[cookie] = isApproved;

        if (isApproved && !approvedCategories.includes(category)) {
          approvedCategories.push(category);
        }
      });

      try {
        await reportConsents(cookieValue, this.config.id, approvedCategories);
      } catch (error) {
        // TODO: Q for report endpoint implementation should we try again here on 409?
        window.Sentry?.captureException(error, "Consent saving failed");
      }

      const stringValue = encodeURIComponent(JSON.stringify(cookieValue));
      const cookieExpirationDate = new Date(
        Date.now() + ONE_YEAR_IN_MS
      ).toGMTString();

      if (this.isCookieSizeAllowed(stringValue)) {
        document.cookie = `${consentCookieName}=${stringValue}; expires=${cookieExpirationDate}; domain=${cookieDomain}; path=/`;

        this.runScriptTags(approvedCategories);

        if (this.dataCallback) {
          const stateText = await this.getConsentStateText(approvedCategories);

          this.dataCallback(stateText, cookieValue);
        }
      }
    }

    /**
     * Returns whether the encoded cookie string fits in cookie size restrictions or not
     *
     * @param {string} cookieString
     * @return {boolean}
     */
    isCookieSizeAllowed(cookieString) {
      const encodedString = encodeURIComponent(String(cookieString)).replace(
        /%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g,
        decodeURIComponent
      );
      const sizeInBytes = new Blob([encodedString]).size;

      if (sizeInBytes >= maxCookieSize) {
        window.Sentry?.captureException(
          `Storable cookie size is too big, config id: ${this.config.id} storable size: ${sizeInBytes}`
        );

        return false;
      }

      return true;
    }

    runScriptTags(approvedCategories) {
      const dataCookieAttribute = "data-cookie-category";
      const scriptElements = document.querySelectorAll(
        `[${dataCookieAttribute}]`
      );

      const cloneScriptElement = (originalElement) => {
        const cloneElement = document.createElement("script");
        cloneElement.type = "text/javascript";
        cloneElement.innerHTML = originalElement.innerHTML;
        return cloneElement;
      };

      const isConsentGivenForCategory = (element) => {
        const category = element.getAttribute(dataCookieAttribute);
        return approvedCategories.includes(category);
      };

      scriptElements.forEach((element) => {
        if (isConsentGivenForCategory(element)) {
          element.replaceWith(cloneScriptElement(element));
        }
      });
    }

    async getCookieDescriptions() {
      await this.fetchConsentConfig();

      if (!this.config) {
        return null;
      }

      return this.config.cookies.reduce(
        (acc, { category, name, purpose, provider, ttl }) => {
          if (!acc[category]) {
            acc[category] = [];
          }

          acc[category].push({
            details: purpose,
            expiration: getExpirationText(ttl),
            name,
            provider,
          });

          return acc;
        },
        {}
      );
    }

    async getConsentStateText(consentedCategories) {
      await this.fetchConsentConfig();

      if (!this.config) {
        return "";
      }

      consentedCategories ??= this.getConsentedCategories();

      return cookieCategories.length === consentedCategories.length
        ? "Allow all"
        : consentedCategories
            .map((category) => categoryTitles[category])
            .join(", ");
    }

    async setDataCallback(callback) {
      this.dataCallback = callback;

      const stateText = await this.getConsentStateText();

      // provide initial data
      this.dataCallback(stateText, this.getConsentValue());
    }
  }

  AWE.component.CookieConsentService = new CookieConsentService();
})();

